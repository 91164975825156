import classNames from 'classnames'
import { useMemo } from 'react'

export default function Semaforo({ className, value }: { value: number | null; className?: string }) {
  const color = useMemo(
    () => (value === null ? '#888888' : 0 <= value && value < 3 ? ['#e42f2f', '#edb918', '#29b332'][value] : undefined),
    [value]
  )

  return (
    <div
      className={classNames('rounded-full transition-colors duration-300', className)}
      style={{ backgroundColor: color }}
    >
      {' '}
    </div>
  )
}
