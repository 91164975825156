import UserPath from '@apb/database/UserPath'
import { Utente } from '@apb/database/schema'
import { useMemo } from 'react'

import { useAuthState } from '../atoms/auth'

export default function useHasRole(role: Utente['role'] | Utente['role'][]): boolean {
  const authState = useAuthState()

  return useMemo(() => {
    if (authState.state !== 'loggedIn') return false

    if (authState.role === 'ADMIN') return true

    if (Array.isArray(role)) return role.some(r => authState.role === r)
    return authState.role === role
  }, [authState, role])
}

export function useIsAdmin() {
  return useHasRole('ADMIN')
}

export function useIsHR() {
  return useHasRole('HR')
}

export function useIsLineManager() {
  return useHasRole('LINE_MANAGER')
}

export function useIsDirezione() {
  return useHasRole('DIREZIONE')
}

export function useIsCollaboratore() {
  return useHasRole('COLLABORATORE')
}

export function useIsSelf(userId: number) {
  const authState = useAuthState()

  return useMemo(() => authState.state === 'loggedIn' && authState.user.id === userId, [authState, userId])
}

export function useCanAccessPath(path: UserPath | null) {
  const authState = useAuthState()

  return useMemo(() => {
    if (path === null) return false
    if (authState.state !== 'loggedIn') return false
    return new UserPath(authState).canAccess(path)
  }, [authState, path])
}
